<template>
  <v-app>


    <v-main app style="padding-top: 0px">
      <Home/>
    </v-main>
    <v-bottom-navigation  dark >
      <v-btn value="recent">
        <span>Home</span>

        <v-icon>mdi-home</v-icon>
      </v-btn>

    </v-bottom-navigation>

  </v-app>
</template>

<script>
import Home from './components/Home';

export default {
  name: 'App',

  components: {
    Home,
  },

  data: () => ({
    //
  }),
};
</script>
