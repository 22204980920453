<template>
  <div id="app">
    <v-app>

      <v-content>
        <section id="hero">
          <v-row no-gutters>
            <!--            <v-img-->
            <!--                :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"-->
            <!--                src="watarun.mp4"-->
            <!--            >-->
            <video autoplay muted loop id="watarun" width="100%" height="100%">
              <source src="/traffic.mp4" type="video/mp4" >
            </video>
            <div class="content">
              <v-theme-provider dark>
                <v-container>

                  <v-row
                      align="center"
                      class="white--text mx-auto"
                      justify="center"
                      style="padding-top: 20%; "
                  >
                    <v-col
                        class="white--text text-center"
                        cols="12"
                        tag="h1"
                        style="padding-bottom: 50px;  "
                    >
                      <div
                          class="font-weight-black"

                      >
                        IOG
                      </div>
                      <div
                          :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                          class="font-weight-light"
                          style="background-color: #cccf00; margin-top: 40px"
                      >
                        DIGITAL TRANSFORMATION EXPERTS
                      </div>

                    </v-col>

                    <v-btn
                        class="align-self-end"
                        fab
                        outlined
                        @click="$vuetify.goTo('#about-me')"
                    >
                      <v-icon>mdi-chevron-double-down</v-icon>
                    </v-btn>
                  </v-row>
                </v-container>
              </v-theme-provider>

            </div>
          </v-row>
        </section>

        <section id="about-me">
          <div class="py-12"></div>

          <v-container class="text-center">
            <h2 class="display-2 font-weight-bold mb-3">ABOUT IOG</h2>

            <v-responsive
                class="mx-auto mb-8"
                width="56"
            >
              <v-divider class="mb-1"></v-divider>

              <v-divider></v-divider>
            </v-responsive>

            <v-responsive
                class="mx-auto title font-weight-light mb-8"
                max-width="720"
            >
              IOG is a fast growing software development company based in the heart of South East Asia.
              With Thai and international experts
            </v-responsive>


          </v-container>

          <div class="py-12"></div>
        </section>

        <section
            id="features"
            class="grey lighten-3"
        >
          <div class="py-12"></div>

          <v-container class="text-center">
            <h2 class="display-2 font-weight-bold mb-3">Our Values</h2>

            <v-responsive
                class="mx-auto mb-12"
                width="56"
            >
              <v-divider class="mb-1"></v-divider>

              <v-divider></v-divider>
            </v-responsive>

            <v-row>
              <v-col
                  v-for="({ icon, title, text }, i) in features"
                  :key="i"
                  cols="12"
                  md="4"
              >
                <v-card
                    class="py-12 px-4"
                    color="grey lighten-5"
                    flat
                >
                  <v-theme-provider dark>
                    <div>
                      <v-avatar
                          color="primary"
                          size="88"
                      >
                        <v-icon
                            large
                            v-text="icon"
                        ></v-icon>
                      </v-avatar>
                    </div>
                  </v-theme-provider>

                  <v-card-title
                      class="justify-center font-weight-black text-uppercase"
                      v-text="title"
                  ></v-card-title>

                  <v-card-text
                      class="subtitle-1"
                      v-text="text"
                  >
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <div class="py-12"></div>
        </section>

        <section id="stats">
          <v-parallax
              :height="$vuetify.breakpoint.smAndDown ? 700 : 500"
              src="tree.jpg"
          >
            <v-container fill-height>
              <v-row class="mx-auto align-center justify-center">

                <div class="text-center display-3 font-weight-black mb-4">
                  Made in Thailand with <v-icon color="red" size="8rem">mdi-heart</v-icon>
                </div>
              </v-row>
            </v-container>
          </v-parallax>
        </section>

        <section id="blog">
          <div class="py-12"></div>

          <v-container>
            <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Blog</h2>

            <v-responsive
                class="mx-auto mb-12"
                width="56"
            >
            </v-responsive>

            <v-row>
              <v-col
                  v-for="({ src, text, title }, i) in articles"
                  :key="i"
                  cols="12"
                  md="4"
              >
                <v-img
                    :src="src"
                    class="mb-4"
                    height="275"
                    max-width="100%"
                ></v-img>

                <h3
                    class="font-weight-black mb-4 text-uppercase"
                    v-text="title"
                ></h3>

                <div
                    class="title font-weight-light mb-5"
                    v-text="text"
                ></div>

                <v-btn
                    class="ml-n4 font-weight-black"
                    text
                >
                  Continue Reading
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

          <div class="py-12"></div>
        </section>

        <section id="careers">
          <div class="py-12"></div>

          <v-container>
            <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Careers @ IOG</h2>


          </v-container>

          <div class="py-12"></div>
        </section>

      </v-content>


    </v-app>
  </div>
</template>

<script>
export default {
  name: 'Home',

  data: () => ({
    articles: [
      {
        src: 'https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
        title: 'Mobile first & Responsive',
        text: 'Phasellus lorem enim, luctus ut velit eget, convallis egestas eros. Sed ornare ligula eget tortor tempor, quis porta tellus dictum.',
      },
      {
        src: 'https://images.unsplash.com/photo-1475938476802-32a7e851dad1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
        title: 'Think outside the box',
        text: 'Nam ut leo ipsum. Maecenas pretium aliquam feugiat. Aenean vel tempor est, vitae tincidunt risus. Sed sodales vestibulum nibh.',
      },
      {
        src: 'https://images.unsplash.com/photo-1416339442236-8ceb164046f8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1892&q=80',
        title: 'Small changes, big difference',
        text: 'Vestibulum in dictum velit, in rhoncus nibh. Maecenas neque libero, interdum a dignissim in, aliquet vitae lectus. Phasellus lorem enim, luctus ut velit eget.',
      },
    ],
    features: [
      {
        icon: 'mdi-heart',
        title: 'Technology',
        text: 'IOG is a technology driven company. Technology is not only a tool but it makes our hearts beat faster.' +
            'Why spend time in meetings when you can create? ',
      },
      {
        icon: 'mdi-update',
        title: 'Transformation',
        text: 'Digital Transformation is not simply transforming an existing business process into the digital landscape.' +
            'Faster and more agile players will use all the tools they have available and will outmaneuver legacy players that refuse to change with the time,' +
            'Digital Transformation is the path of transforming the business through technology and not doing the same-old but digital.'
      },
      {
        icon: 'mdi-account',
        title: 'Tribe',
        text: 'Become part of the tribe at IOG. Not simpy a team, when you work here you are part of the tribe. ' +
            'We help each and support each other in our tribe. Our goal is to build the foundation where our tribe can grow.' +
            'A tribe is a flat structure, everyone has a voice. Our development processes are agile, because we need to be fast and we need to deliver.',
      },
    ],
    stats: [
      ['24k', 'Github Stars'],
      ['330+', 'Releases'],
      ['1m', 'Downloads/mo'],
      ['5m', 'Total Downloads'],
    ],
  })
}
</script>
<style>
h1 {
  font-size: 10rem !important;
}

.content {
  position:absolute;
  float:left;
  width: 100%;
}
</style>